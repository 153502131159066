import React, { useEffect, useState } from "react"
import { navigate, PageProps } from "gatsby"
import SEO from "../components/seo"
import PageTitle from "../components/utils/PageTitle"
import Layout from "../components/layout"
import { Query } from "../../types/graphql-types"

const NotFound: React.FC<PageProps<Query>> = ({ location }) => {
  const [time, setTime] = useState(5)
  useEffect(() => {
    const interval = setInterval(() => {
      setTime(c => c - 1)
    }, 1000)
    return () => clearInterval(interval)
  }, [])
  if (time === 0) {
    navigate("/") // redirecting to home page
  }

  return (
    <div style={{ height: "100vh" }}>
      <div className="layout-background-color" style={{ height: "100vh" }}>
        <Layout location={location} selectPageName={"Contact"}>
          <SEO
            title="Not Found"
            description="お探しのページは見つかりません"
            lang={"ja"}
          />
          <PageTitle title="not found" />
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div>
                <p>お探しのページは見つかりません</p>
              </div>
              <div>
                <p>{time}秒後にトップページに遷移します</p>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "16px"
              }}
            >
              <div>
                <p>The page you are looking for cannot be found.</p>
              </div>
              <div>
                <p>Redirecting to the home page in {time} seconds</p>
              </div>
            </div>
          </div>
        </Layout>
      </div>
    </div>
  )
}
export default NotFound
